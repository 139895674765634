<template>
  <div id="slideWrap">
    <div class="swiper-container mainSlide">
      <div class="swiper-wrapper">
        <!--div class="swiper-slide" v-for="item in banner" v-bind:key="item.bannerIdx">
          <a :href="item.domain">
            <img :src="item.bannerImg" alt="slide">
          </a>
        </div-->
        <div class="swiper-slide">
          <img src="@/assets/img/sbanner2.png">
        </div>
        <div class="swiper-slide">
          <img src="@/assets/img/sbanner0.jpg">
          <!--button class="btn1" @click="onCasinoSelectGame('sport', 'splus')">라이브 스포츠 바로가기</button-->
        </div>
        <div class="swiper-slide">
          <img src="@/assets/img/sbanner1.jpg">
          <!--button class="btn2" @click="onCasinoSelectGame('sport', 'splus')">라이브 스포츠 바로가기</button-->
        </div>
      </div>
      <div class="swiper-button-prev" role="button"></div>
      <div class="swiper-button-next" role="button"></div>
    </div>
    <!--div class="swiper-pagination swiper-pagination-main">
    </div-->
  </div>
</template>

<script>

import { mapState } from 'vuex'

export default {
  name: 'UiSwiper',
  title: 'Navigation',
  data () {
    return {
      bannerList: [
      ]
    }
  },
  mounted () {
    this.loadSwiper()
  },
  watch: {
    banner () {
      if (this.banner) {
        this.loadSwiper()
      }
    }
  },
  computed: {
    ...mapState([
      'banner'
    ])
  },
  methods: {
    loadSwiper () {
      // const list = this.banner
      // eslint-disable-next-line no-undef,no-new
      new Swiper('.swiper-container.mainSlide', {
        autoplay: { // 자동 슬라이드 설정 , 비 활성화 시 false
          delay: 4000, // 시간 설정
          disableOnInteraction: false // false로 설정하면 스와이프 후 자동 재생이 비활성화 되지 않음
        },
        loop: true,
        slidesPerView: 'auto',
        spaceBetween: 100,
        centeredSlides: true,
        effect: 'coverflow',
        coverflowEffect: {
          rotate: 0
        },
        // If we need pagination
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        pagination: {
          clickable: true,
          el: '.swiper-pagination-main'
        }
        // pagination: {
        //  el: '.swiper-pagination',
        //  clickable: true,
        //  renderBullet: function (index, className) {
        //    const title = list[index].bannerTitle || ''
        //    return '<div class="' + className + '"><span>' + title + '</span></div>'
        //  }
        // }
      })

      if (this.banner) {
        setTimeout(function () {
          this.$emit('loaded', true)
        }.bind(this), 500)
      }
    }
  }
}
</script>

<style src="@/styles/swiper.min.css"></style>

<style>
#slideWrap {position: relative;width: 100%;}
#slideWrap .swiper-pagination-main {bottom: -20px; right: 0; display: flex; gap: 10px;}
#slideWrap .swiper-pagination-main .swiper-pagination-bullet {width: 8px; height:8px; background: #fff; opacity: 1; box-shadow: 0px 2px 2px #0000004D; }
#slideWrap .swiper-pagination-main .swiper-pagination-bullet-active {background: #a9aab3; }

.mainSlide {}
.mainSlide .swiper-slide {width: 75%;display: flex;align-items: center;gap: 10px;}
.mainSlide .swiper-slide img {width: 100%;height: 440px;object-fit: cover;object-position: center;}

.mainSlide .swiper-button-next {right: 10%;}
.mainSlide .swiper-button-prev {left: 10%;}
/*
.mainSlide .swiper-button-next,
.mainSlide .swiper-button-prev {width: 30px;height: 30px;background: #201f1f no-repeat;background-size: 7px;background-position: center;margin-top: -15px;}
.mainSlide .swiper-button-next {right: 20%;}
.mainSlide .swiper-button-prev {left: 20%;}
.prevnext {width: calc(85% + 60px);margin: 0 auto;position: relative;}
*/
.s_ment {position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);color: #413838;width: 80%;max-width: 1314px;margin: 0 auto;}
.s_ment h2 {font-size: 12pt;color: #e5972d;margin-bottom: 15px;}
.s_ment h1 {font-size: 20pt;margin-bottom: 20px;}
.s_ment h3 {font-size: 12pt;}
.swiper-slide {
  position: relative;
}
.swiper-slide button {
  position: absolute;
  z-index: 2;
  bottom: 53px;
  left: 50%;
  transform: translateX(-50%);
  width: 294px;
  height: 55px;
  color: #fff;
  border: 0;
  font-size: 20px;
  cursor: pointer;
}
.btn2 {
  background: #B400FF;
}
.btn1 {
  background: #017EFF;
}
@media (max-width: 1000px) {
   .mainSlide .swiper-slide {width: 100%;}
   .mainSlide .swiper-slide img {height: 200px;}
   .mainSlide .swiper-button-next {right: 0;}
   .mainSlide .swiper-button-prev {left: 0;}
   .swiper-slide button{
    width: 138px;
    height: 25px;
    font-size: 12px;
    bottom: 25px;
   }
}
</style>
